<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CREW CREATE

type    : view

uses    : header-view

route   : /crews/create
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="crew-create-component">
    <header-view
      title="Create a New Work Crew"
      previous_page="/crews"
      :btnOptions="[
        {
          name: 'Save',
          action: this.handleSubmit,
        },
      ]" />
    <v-form
      ref="form"
      @submit.prevent="handleSubmit"
      id="crewForm"
      class="ma-3">
      <v-container>
        <v-layout align-start justify-start row>
          <v-flex xs12>
            <v-text-field
              id="name"
              v-model="crew.name"
              label="Name"
              :rules="validate_name" />

            <v-subheader>Foreman</v-subheader>
            <v-select
              :items="usersSelectOptions"
              label="Assign Foreman"
              v-model="selectedForeman"
              solo></v-select>

            <v-select
              id="status"
              v-model="crew.status"
              :items="possible_status"
              label="Status"
              :rules="validate_status" />
          </v-flex>
        </v-layout>

        <div v-if="errorMessage !== ''" class="headline">
          {{ errorMessage }}
          <v-btn @click="errorMessage = ''"> OK </v-btn>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import HeaderView from '@/components/header-view';
  import Users from '@/services/Users.service.js';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'CrewCreate',
    components: {
      'header-view': HeaderView,
    },
    data() {
      return {
        possible_status: ['Active', 'Inactive'],
        errorMessage: '',
        loader: false,
        loading4: false,
        crew: {},
        usersSelectOptions: [],
        submitted: false,
        selectedForeman: '',
        validate_name: [(v) => !!v || 'This is required'],
        validate_status: [(v) => !!v || 'This is required'],
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    created() {},
    async mounted() {
      if (!this.$auth.userProfile.tenant_uuid) {
        console.log('could not get tenant uuid, aborting...');
        return;
      }
      // create new crew object
      this.crew = Tenants.blankCrew();
      this.crew.tenant_uuid = this.$auth.userProfile.tenant_uuid;

      this.busy = true;
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      var users = await Users.getUsersByTenant(
        this.$auth.userProfile.tenant_uuid,
        accessToken
      );

      if (users) {
        // console.log( users)
        this.usersSelectOptions = users.map((x) => {
          return {
            text: x.name,
            value: x.uuid,
          };
        });

        // console.log( "heres the select options" )
        // console.log( this.usersSelectOptions )

        this.busy = false;
      } else {
        this.errorMessage = 'API call failed';

        this.busy = false;
      }
    },
    methods: {
      async handleSubmit() {
        // console.log( "heres the crew" )
        this.crew.foreman_uuid = this.selectedForeman;
        // console.log( this.crew )
        if (!this.$refs.form.validate()) {
          return false;
        }
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var crew = await Tenants.createCrew(this.crew, accessToken);

        if (crew) {
          this.$router.push('/crews/view/' + crew.uuid);
        } else {
          this.errorMessage = 'error creating new crew';
          // TODO: fix this use of $nextTick. It's probably a mistake
          // since it doesn't have a callback.
          // eslint-disable-next-line vue/valid-next-tick
          this.$nextTick();
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
